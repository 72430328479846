@mixin project-cart{


	.cart{
		border-collapse: collapse;
		margin-bottom: 5rem;
		
		thead{
			tr{
				th{
					border-bottom: 1px solid $light-gray;

					&.mid{text-align: center;}
				}
			}
		}

		tbody{
			border: 0;

			tr{

				td{
					background: $white;
					border-bottom: 1px solid $light-gray; 

					&:nth-child(1){
						width:20%;

						img{
							height: auto;
						}
					}
					&:nth-child(2){
						width:30%;
						a{
							color: $black; 

							span:nth-child(1){ 
								font-size: 1rem;
								font-weight: 600;
								text-transform: uppercase;
							}
							span:nth-child(2){
								font-size: 0.8rem;
								display: inline-block;
								margin-top: 0.5rem;
							} 
						}  
					}
					&:nth-child(3){width:14%;font-size:1.1rem;font-weight: 600;text-align: center;}
					&:nth-child(4){width:14%;}
					&:nth-child(5){width:14%;font-size:1.1rem;font-weight: 600;text-align: center;}
					&:nth-child(6){width:8%;text-align: center; img{cursor:pointer;} }
				}

				&:last-child{
					td{
						border-bottom: 0;
						padding: 0;

						.button{
							margin-top: 1.8rem;
						}

						&:nth-child(4){ text-align: right; }
					}
				}

			}
		}

		&.summary{
			margin-bottom: 1rem;

			td:nth-child(4){ 
				width: 6%;
				text-align: center;
				font-weight: 600;
			}
		}

		.cartTotal{
			font-size: 1rem;
			text-align: left;
			padding: 1rem 0;

			span{ display:inline-block; font-weight: 600; font-size: 1.1rem; text-transform: lowercase;}
		}

	}


	.cart-radio-options{
		
		&.spacer{
			border-bottom: 1px solid $medium-gray;			
		}

		ul{
			margin: 1rem 0 0.6rem 1rem;

			li{
				color: $black;

				input{
					
				}

				&.active{
					font-weight: 600;
				}
			}
		}
	}

	.mobile-order-summary{
		ul{
			margin: 1rem;

			li{
				color: $black;
				font-size: 1rem;

				span{ float: right; } 
			}
		}
	}

	.cart-container{
		margin-bottom: 3rem;

		label{
			.active{ font-weight: 600; }
		}

		ul.terms{
			li{
				color: $black;
				text-align: center;	
				font-size: 0.875rem;

				a{
					color: $blue;
					font-weight: 600;
				}
			}
		}

		.summary-sidebar{
			padding-top: 0.5rem;
			padding-left: 1rem;
			color: $black;
		}		

		.sub-header-2{

			h4{
				color: $black;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 1rem;
			}
		}		

		.top-space{
			margin-top: 1rem;
		}
		
		.cell.spacer{
			border-top: 1px solid $light-gray;
		}	

		.order-confirmation{
			color: $black;
			padding: 2rem 0 4rem 0;
			
			h2{ font-size: 1.5rem; }
		}	
	}

	.cart-summary-mobile{
		padding: 1rem 0;
		font-weight: 600;
		color: $black;
		span{
			display: inline-block;
			float: right;
		}
	}

	@include breakpoint(medium down) { 

		.cart-item{
			width: 100%;
			min-height: 120px;
			/* background: beige; */
			padding: 2rem 0;
			border-bottom: 1px solid $light-gray;		
			
			.image{
				width: 25%;
				/* background: red; */
				padding-right: 16px;
				position: relative;
				float: left;							
						
				img{
				}

				span{
				    position: absolute;
				    top: -1rem;
				    right: 9px;
				    display: block;
				    background: $blue;
				    width: 20px;
				    height: 20px;
				    border-radius: 10px;
				    font-size: .875rem;
				    font-weight: 600;
				    color: white;
				    text-align: center;
				}
			}

			.name{
			    width: 46%;
			    position: relative;
			    /* background: green; */
			    float: left;
			    padding-left: 0;
			    padding-right: 6px;
			    a{
				    font-size: 0.875rem;
				    color: black;
				    font-weight: 600;
			    }
			}

			.amount{
				float:left;
				width: 10%;
				text-align: center;
				color: $black;
				font-weight: 600;

				input{
					width: 100%;
					padding: 0;
					font-weight: 500;
				}
			}

			.price{
			    position: relative;
			    /* background: pink; */
			    width: 19%;
			    float: left;
			    text-align: right;
			    font-size: 0.875rem;
			    font-weight: 600;
			    padding-left: 0;
			    color: black;

			    span{
			    	display: block;
			    	cursor: pointer;
			    	color: $blue;
			    }
			}
		}
	

		.cart-footer{
			width: 100%;
			position: sticky;
			bottom: 0;
			background-color: $white;
			z-index: 100;

			div:nth-child(1){
				color: $black;
				border-bottom: 1px solid $light-gray;
				font-weight: 600;
				font-size: 1rem;		
				padding: 1rem 0;			

				span{ display:block; float:right; }
			}
			div.continue{
				a{
					color: $black;
					background: $white;
					margin-bottom: 3px;
					font-size: 1rem;						
				}
			}
		}	
	

		.cart-container{
			.sub-header{
				h4{
					color: $black;
					font-size: 1rem;
				}	
			}

			.button{
				margin: 1rem auto;
				width: 80%;
				display: block;
			}
		}

	}

	@include breakpoint(medium up) {  }


	@include breakpoint(large up) { 

		.cart-container{
		
			.sub-header{
				border-bottom: 1px solid $light-gray;
				padding-bottom: 0.5rem;

				h4{
					color: $black;
					font-size: 1.1rem;
				}	
			}

			.sub-header-2{

				h4{
					color: $black;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 1rem;
				}

				&.top-space{
					margin-top: 1rem;
				}
			}

			.cell.spacer{
				border-top: 1px solid $light-gray;
			}

			.mobile-order-summary{
				padding: 1.2rem!important;

				ul{
					margin: 0;
				}   	
			}   	

			.summary-sidebar{
				padding-top: 0.5rem;
				padding-left: 2rem;
				color: $black;
			}

			.boldit{
			    width: 14%;
			    font-size: 1.1rem;
			    font-weight: 600;
			    text-align: center;				
			}

			#order-data-tabs{
				border: 0;

				li{
					width: 33.333%;
					background: $white;

					a{
						color: $black;
						font-size: 1rem; 
						outline: none;
					}

					&.is-active{
						border-bottom: 2px solid $light-gray;

						a{ 
							font-weight: 600; 
							background: $white;
						}
					}

				}
			}

			.order-data-tabs-content{
				margin-top: 1rem;


			}
		}
	}

	@include breakpoint(xlarge up) { 

	}

	@include breakpoint(xxlarge up) { 

	}	

}