@mixin project-product{

	.products-row{
		.product-item{
			background-color: $white;
			border: 0px solid red;
			margin-bottom: 3rem;
			position: relative;

			.img-container{
				height: 240px;
				background-color: $white;
				position: relative;

				img{
				    position: absolute;
				    top: 50%;
				    transform: translate3d(0, -50%, 0);
				    width: 100%;
				}
			}

			&.large{
				.img-container{
					height: 320px;
					img{ left: 5%;  width: 90%; }
				}
			}

			a{
			    display: block;
			    text-align: center;
			    margin: 0 0 1rem 0px;
			    color: $black;
			    font-weight: 600;				
			}

			p{
				font-size: 0.875rem;
				color: $black;
				text-align:center;
			}

			span{
			    display: block;
			    text-align: center;
			    padding-bottom: 1rem;
			    font-size: 1.3rem;				
			    color: $blue;
			}

			.overlay{
				display: none;
				position: absolute;
			    /*border: 0px solid purple;*/
			    top: 0;
			    width: 100%;
			    height: 100%;
			    /*background-color: rgba(0,0,0,0.03);*/

			    ul{
			    	width: 100%;
			    	margin:0;
			    	padding:0;
			    	top: 50%;
    				position: absolute;
					transform: translate3d(0, -50%, 0);

			    	li{
			    		margin:0; 
			    		padding:0;
			    		float:left;
			    		width: 100%;
			    		padding: 2%;

			    		&:first-child{padding: 2% 1% 2% 2%;}
			    		&:last-child{padding: 2% 2% 2% 1%;}

			    		.button{
			    			margin:1rem 0;
			    			width: 100%;
			    			color: #fff;
							font-size: 1rem;
			    		}
			    	}
			    }
			}
			&:hover{
				.overlay{ display: block; }
			}
		}
	}


	/*#product-wrapper{*/

		#product-images{
			
			.main-image{
				border: 1px solid purple;
				cursor: pointer;
			}

		}

		#product-actions, .cart{
			/*background-color: magenta;*/
			h6{
				color: $black;
				font-weight: 600;
				margin-bottom: 0.5rem;
			}
			h3{
				text-transform: uppercase;
				font-size: 1.5rem;
				font-weight: 600;
				line-height: 1.8rem;
				color: $black;
				/*margin-bottom: 2rem;*/
			}

			.action-row{
				margin-bottom: 0.8rem;
			}

			.product-prices, .product-value{
				span{
					display: block;
					float: none;
				}
				.prev-price{
					display: inline-block;
					color: $medium-gray;
					text-decoration: line-through;
					font-size: 1.2rem;
				}
				.price, .value{
					color: $blue;
					font-size: 2rem;
					line-height: 2.2rem;
    				font-weight: 500;
				}	
				.value{
					color: $black;
				}

			}

			#installment{
				margin-top: 0.3rem;
			}		

			.amounts{
				/*margin-top: 0.3rem;*/
				margin-bottom: 1.4rem;

				@include no-selection;
				span{
					font-size: 1.1rem;
					font-weight: 600; 
					color: $black;

				    display: block;
				    float:left;
				    border: 1px solid $light-gray;
				    width: 2.8rem;
				    height: 2.8rem;
				    text-align: center;
				    padding: 0.5rem;
				    border-right: 0;
				    margin: 0;							


					&.product-decrease{cursor: pointer; }
					&.product-amount{ }
					&.product-increase{ cursor: pointer; border-right: 1px solid $light-gray;}
				}
			}

			.composed-elements-header{
				margin-top: 0.5rem;

			}

			.pt-simple{
				height: 1rem;
			}

			.action-buttons{
				margin-top: 0.5rem;

				button{
					padding: 0.70rem 0;
					img{
						max-height: 1.8rem;
						max-width: 1.8rem;
						margin-left: 0.7rem;
					}
				}
			}	

			
				input{
					[type='checkbox'], [type='radio']{
					    float: left;
					    margin-top: 0.4rem;						
					}
				}				
			
		}  

		.product-details{
			margin: 3rem 0;

			.tabs{
				border: 0;

				.tabs-title{
					a{
						color: $black;
						background-color: $white;
						font-size: 0.875rem;
						text-transform: uppercase;
    					font-weight: 600;
    					min-width: 150px;
    					text-align: left;
    					padding: 1.25rem 0rem;
    					margin-right: 2.5rem;

    					&[aria-selected='true']{
    						background-color: $white;
    						border-bottom: 2px solid $black;
    					}
					}
				}
			}

			.tabs-content{
				border: 0;

				.tabs-panel{
					padding: 2rem 0;
				}
			}
		}


		.panel-description{
			max-width: 600px;
		}

		.panel-attributes{
			table{
				tbody{ border: 0; }
				border: 0;
				border-collapse: collapse;

				tr{
					td{	
						padding: 0.7rem 0 0.7rem 2rem;
						font-size: 1rem;
						background-color: $white;
						border-bottom: 1px solid $black;
						
						&:first-child{
							font-weight: 600;
							width: 50%;
						}
					}
				
					&:last-child{
						td{border-bottom: 0;}
					}
				}


			}
		}
	/*}*/

	.accordion-title{
		border: 0;
	    font-size: 1rem;
	    font-weight: 600;
	    color: $black;
	    background-color: $light-gray;
	    padding: 1.5rem 1.2rem;		
	    border-bottom: 1px solid $white;
	}

	.accordion-content{
		padding: 1.5rem 1.2rem;
		border: 0;
	
		&.panel-attributes{
			table{
				tr{
					td{
						padding: 0.5rem 0.625rem 0.625rem;
					}
				}
			}
		}

	}


	@include breakpoint(medium down) { 
   	
		#product-actions{
			margin-top: 1.5rem;
		}

		.accordion-item:last-child{
			.accordion-content:last-child{
				border-bottom: 0;
			}
		}

	}

	@include breakpoint(large up) { 
   	
   		.products-row{
    
   		}

	}
}