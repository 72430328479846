@mixin project-user{

	.user-menu-header{
		margin-top: 1rem;
		margin-bottom: 1rem;

		h5{
			font-size: 1rem;
			text-transform: uppercase;
			color: $black;
			font-weight: 600;
		}
	}

	.user-menu-box{
		display: block;
		width: 100%;
		border: 1px solid $light-gray;
		text-align: center;
		color: black;
		padding: 2rem 0;

		img{
		    max-height: 2.6rem;
		    margin: 1rem 3rem;			
		}
		span{
			text-transform: uppercase;
			font-weight: 600;
			font-size: 0.875rem;
		}

		&.active{
			background-color: $light-gray;
		}
	}

	.user-profile-content{
		margin-bottom: 3rem;

		.user-menu-desktop{
			margin-bottom: 2rem;
		}
		form{
			margin-top: 1rem 0;
		}

		.switcher{
			display: block;
			background: $light-gray;
			width: 100%;
			margin-bottom: 1px;
			font-size: 1rem;
			font-weight: 700;
			padding: 1rem 1rem 1rem 2rem;
			color: $black;

			img{
				margin-top: 0.5rem;
				width: 1.2rem;
			}
		}
	}

	.user-addresses{
		margin: 2rem 0;
		thead{
			td:nth-child(1){width: 200px;}
			td:nth-child(2){width: 400px;}
			td:nth-child(3){width: 3rem;}
			td:nth-child(4){width: 3rem;}
		}
	}

	@include breakpoint(medium down){ 


	}

	
	@include breakpoint(medium up){ 


	}


	@include breakpoint(large up){ 
		
		.user-menu-header{
			h5{
				font-size: 0.875rem;
			}
		}

	}

	@include breakpoint(xlarge up){ 


	}


}