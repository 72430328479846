@mixin project-controls{

	.button{
		background-color: $blue;
		font-size: 1rem;
		padding: 1.469rem 1rem;
		color: $white;
		border: 0;
		font-weight: 600;
		
		&:hover{
			background-color: $navy;
		}

		@include no-selection;

		&.navy{
			background-color: $navy;
		}

		&.gray{
			background-color: $light-gray;
			color: $black;
		}

		/*
		&.white{
			background-color: $white;
			border: 2px solid $black;
			color: $black;
			font-weight: 600;
			padding: 0.49rem;
			font-size: 0.875rem;
		}

		&.black{
			background-color: $black;
			color: $white;
			font-weight: 600;
			font-size: 0.875rem;
			border: 2px solid $black;
			padding: 0.49rem;
		}
		*/

		&.wide{ width: 100%; }
	}

	.message{
	    font-weight: 700;
	    padding: 1rem 2rem;		
	    margin: 1rem 0;

	    &.fail{
			background-color: $extra-gray;
		    color: $white;
	    }

	    &.success{
			background-color: $blue;
		    color: #fff;
	    }

	    &.info{
	    	background-color: $light-gray;
	    	color: $black;
	    }

	    &.hint{
	    	background-color: $light-gray;
	    	color: $black;
	    }

	}

	form{
		width: 100%;

		&.regular{
			[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
			    font-size: 1rem;
			    padding: 1.3rem 0.5rem;
			    margin-bottom: 1rem;		
			    box-shadow: none;
			}

			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
			   /* IE10+ specific styles go here IE11 hack */  
				[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
				    padding: 0 0.5rem!important;
				}			   
			}			
		}

		.form-section-head{
			color: $black;
			font-size: 1rem;
			font-weight: 700;
			border-bottom: 1px solid $light-gray;
			padding-bottom: 1rem;
			margin: 1rem 0;
		}

		.subheader{
			display: block;
		    color: $black;
		    margin-bottom: 1rem;
		    font-size: 1rem;
		}

		.separator{
		    display: block;
		    width: 100%;
		    border-top: 1px solid $light-gray;
		    margin: 4rem 0 1rem 0;		

		    &.margin-small{
		    	margin: 1rem 0 1rem 0;
		    }	
		}

		.form-section-header{
		    margin-bottom: 2rem;
		    padding-bottom: 0.8rem;
		    border-bottom: 1px solid $light-gray;
		    color: $black;
		    font-weight: 600;			
		}

		.checkbox-container{
		    height: 43.59px;
		    margin-bottom: 1.6rem;
		    padding-top: 0.6rem;			
		}

		label{
			&.txt{
				input{margin-top: 0.5rem;}
			}

			&.error{
				color: #e63d3d;
				[type='text'], select{
					border-color: #e63d3d; 
				}
			}
		}

		.button{
			&.brand{
				min-width: 170px;
			}
		}
	}

	table{
		thead{
			background-color: $white;
			border: 0;
			border-bottom: 1px solid $light-gray;

			td{

				font-weight: 500;
			}
		}
		tbody{
			border: 0;
			border-bottom: 1px solid $light-gray;

			td{
				color: $black;
				font-size: 0.875rem;

				img{height: 1.3rem;}
			}
		}
	}

	.custom-pane-content{
		select, .button{
			height: 3.1rem !important;
		}

		label{
			font-size: 1rem;
			line-height: 2.2;
		}

		button.aligned{ margin-top: 2.16rem; }
	}

	.content-filter-head{
		color: $black;
		font-size: 1rem;
		font-weight: 700;
		padding-bottom: 1rem;
		margin: 1rem 0 0 0;
	}
	.content-filter{ margin-bottom: 1rem;}


	.breadcrumbs{
		margin: 0.5rem 0 0.5rem 0;

		nav{
			ul{
				li{
					text-transform: uppercase;
					font-size: 0.860rem;
					font-weight: 600;
					color: $black;

					a{
						text-transform: inherit;
						font-weight: inherit;
						font-weight: inherit;
						color: inherit;
					}
				}
			}
		}
	}

	.drop-down{

	    position: relative;
	    display: inline-block;

		.drop-btn {
		    border: none;
		    cursor: pointer;
		}		

		/* Dropdown Content (Hidden by Default) */
		.drop-down-content {
		    display: none;
		    position: absolute;
		    background-color: $navy;
		    min-width: 200px;
		    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		    z-index: 1;
			left: -0.2rem;

		    a {
			    color: black;
			    padding: 12px 16px;
			    text-decoration: none;
			    display: block;
			    border-bottom: 1px solid $white;
			    color: $white;
			    text-transform: uppercase;
			}

			a:last-child{border-bottom: 0;}

			a:hover {background-color: #44606f; }
		}


		/* Show the dropdown menu on hover */
		&:hover .drop-down-content {
		    display: block;
		}

		&:hover .drop-btn { }

		/*
		.submenu{ 
			display: none;
			border: 1px solid red;

			li{
				float: none;

			}
		}

		.menu-toggler:hover{
			.submenu{
				display: block;
			}
		}

		/*
		.menu-toggler:hover{
			& ~ .submenu{
				display: block;
			}
		}
		*/
	}

	.paginator-wrapper{
		background-color: $white;
		padding: 2rem 0;
	}

	.paginator{
	    /*padding-left: 1.2rem;
	    padding-right: 1.2rem;*/
	    border: 0;
	    border-radius: 0;
	    font-size: 1.125rem;
	    height: 3.625rem;
	    /*background-image: linear-gradient(270deg, $button-gradient-left 0%, $button-gradient-right 100%);*/
	    background-color: $white;
	    display: inline-block;

	    ul{
	    	margin: 0;  
	    	display: inline-block;
	    	li{
	    		float:left;  
	    		a, span{
	    			color: $black;
	    			font-weight: 300;
    				font-size: 2.2rem;
    				img{ height: 1.4rem;}
	    		}

	    		form{
	    			input{
    					width: 4rem;
						background-color: transparent;
						border: 0;
						border-left: 2px solid #fff;
						border-right: 2px solid #fff;
						height: 3.626rem;
						text-align: center;
						color: $navy;
						font-weight: 500;
    					font-size: 1.125rem;
    					box-shadow: none;
    					border-bottom: 2px solid #000;
    					cursor: pointer;
	    			}
	    			input:focus{
	    				color: $blue;
	    				background-color: $white;
	    				border: 0;
	    			}
	    		}
	    	}
			li.prev, li.next{
				width: 4.2rem;
			}
			li.prev a, li.prev span{padding: 1rem 1rem 1rem 0;}
			li.next a, li.next span{padding: 1rem 0 1rem 1rem;}

			
			li.from, li.all-pages{   
				color: $black;
				height: 3.626rem;
				padding: 1rem 1rem 0.9rem 1.5rem;
    			font-size: 1.1rem;
			}
			li.all-pages{ font-weight: 500; } 

			li.disabled{
				a, span{
					color: #dcdcdc;
					cursor: not-allowed;
				}	
			}

	    }
	}

	.header{
		h2{
		    color: $black;
		    text-transform: uppercase;
		    font-size: 1.15rem;
		    font-weight: 600;
		    margin-top: 0.7rem;		
		}
	}

	.steps{
		/*height: 10rem;
		padding: 3rem 0;
		*/
		margin-top: 0;

		ul {
			align-content: center;
			align-items: center;
			counter-reset: stepCount;
			display: flex;
			justify-content: space-around;
			margin: 3rem auto 6rem;  /* for codepen */
		}

		li {
			background: $blue;
			color: white;
			content: ' ';
			display: flex;
			flex-grow: 1;
			height: .1em;
			line-height: 1em;
			margin: 0;
			position: relative;
			text-align: right;
			z-index: -1;

			.step-nr{
				color: $blue;
				position: absolute;
				top: -2.2rem;
				transform: translateX(-50%);
			    width: 4rem;
			    text-align: center;
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 600;				
				text-transform: uppercase;
			}
			.step-desc{
				color: $black;
				position: absolute;
				top: 1.5rem;
				display: inline-block;
				transform: translateX(-50%);
			}

		}

		li::before {
			background: $blue;
			border-radius: 50%;
			color: white;
			content: '';  /* counter(stepCount); */
			counter-increment: stepCount;
			height: 1.2em;
			left: -0.5em;
			line-height: 2em;
			position: absolute;
			text-align: center;
			top: -.55em;
			width: 1.2em;
		}

		li.active {
			background-color: $blue;

		}

		li.active~li {
			background-color: $blue;

			.step-nr{ color: $light-gray; }
		}

		li.active~li::before {
			background-color: white;
			border: 2px solid $light-gray;
		}

		li:last-child {
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: 0;
		/* Shorthand: flex: 0 1 0; */
		}

		ul.bigger {
		font-size: 1.3em;
		}

		ul.highlight-active li.active::before {
		font-size: 1.6em;
		background: navy;
		}

		ul.roman li::before {
		content: counter(stepCount, upper-roman);
		}

		ul.triangle li::before {
		width: 0;
		height: 0;
		border-radius: 0;
		border-left: 1em solid white;
		border-right: 1em solid white;
		border-bottom: .8em solid dodgerblue;
		content: '';
		top: -.65em;
		}

		ul.triangle li:first-child::before {
		left: 0;
		}

		ul.triangle li.active~li::before {
		border-bottom-color: lightblue;
		}		
	}  
 
	@include breakpoint(medium down) { 

		.cell{
			&.header{
				h2{
				    border-bottom: 1px solid $medium-gray;
				    padding-bottom: 1.2rem;
				    margin-bottom: 1.2rem;
				}
			}
		}

	}

	@include breakpoint(medium up) { 


	}

	@include breakpoint(large up) { 

		.button{
			font-size: 1rem;
			padding: 1.125rem;
		}

		form.regular{
			[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
			    font-size: 0.875rem;
			    padding: 1.3rem 0.5rem;
			    /*margin-bottom: 1.6rem;*/		
			}

			.subheader{
			    font-size: 0.875rem;
			}			
		}

	}

	@include breakpoint(xlarge up) { 


	}

	@include breakpoint(xxlarge up) { 


	}	

}


@mixin no-selection{
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */					

}