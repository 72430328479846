@mixin project-top{

	#pre-top{
		background-color: $bg-gray;
		
		.pre-top-content{
			height: 2.7rem;

			ul{
				margin: 0;

				li{
					float: left;
					line-height: 2.3rem;

					a{
						font-size: 0.75rem;
						font-weight: 600;
						color: $black;
					}
				}

				&.quick-contact{
					li{
						padding-right: 2rem;
						text-align: left;
						color: $black;
						font-size: 0.8rem;

						a{
							margin: 0 0.5rem;
						}
					}
				}
				&.submenu{
					li{
						padding-left: 2.5rem;
						text-align: right;
						
						a{
							text-transform: uppercase;
							font-size: 0.75rem;
						}
					}
				}
			}
		}
	}

	#top{
		height: 4.75rem;
		background-color: $white;


		.logo{ height:1.8rem; }

		.input-group{ 
			margin: 0; 
			border: 1px solid $black;

			input{
				outline: none;
				border: 0;
				box-shadow: none;
				transition: none;				
			}

			button{
				background-color: $white;
				border-left: 1px solid $black;
				
				img{
					width: 1.7rem;
				}
			}

			&:focus{
				outline: none;
				background-color: #fefefe;
				box-shadow: 0 0 5px #cacaca;
				transition: box-shadow 0.5s, border-color 0.25s ease-in-out;				
			}
		}

		.ico-button{
			img{ height: 1.7rem; cursor: pointer; }
			span{cursor: pointer;font-weight: 600;margin-left: 5px;}
		
			&.sc-container{ text-align: right; }
		}

		.mobile-menu-toggler{
			img{ max-width: 3rem; }
		}


	}

	.desktop-top-menu-container{

		.dropdown-pane{
			top: 196px!important;

		}

		.custom-pane-content{
			/*padding: 1rem 0 0 0;*/

			h5{
				font-size: 0.875rem;
				color: $black;
				text-transform: uppercase;
				font-weight: 700;
				margin: 0.8rem 0 0 0.9rem;
			}
		}

		#desktop-top-menu{
			height: 4.75rem;

			.cell{
				/*height: 100%;*/

				/*
				a:hover{
					border-left: 1px solid $bg-gray;
					border-right: 1px solid $bg-gray;
				}
				*/
			}
		}

		#dtm-list{
			width: 100%;
			height: 75px;
			margin: 0 0 3px 0;
			border-top: 1px solid #ececec;
			border-bottom: 1px solid #ececec;

			li.me{
				float: left;
				width: 14.285%;

				a.me-link{
					text-transform: uppercase;
					color: $black;
					font-size: 0.875rem;
					font-weight: 700;
					height: 75px;
					display: block;
					padding: 17px 10px;
				}

				div.me-container{

					
					transition: all 0.5s;		
				    
				    background: $white;
				    z-index: 100;
				    position: absolute;
				    width: 100%;
				    left: 0;
				    min-height: 100px;		
				    padding: 2rem 0;
				    text-align: left;

				    ul{
				    	margin: 0;

				    	li{
				    		padding: 0.5rem 0;
				    	}

				    }

					a, li.header{
						/*height: 100%;*/
						color: $black;
						font-size: 0.875rem;
						/*display: block;*/
					}

					a.header, li.header{
						text-transform: uppercase;
						font-weight: 700;
					}			

					li.a:hover{ color: $blue; }	    

					form{
						margin-top: 1.5rem;
					}

					button{
						height: 39px;
						padding: 0;
					}
				}

				&:hover{ 
					border-left: 1px solid #ececec;
					border-right: 1px solid #ececec;

					div.me-container{
						visibility: visible!important;
						transition-delay: 0.5s;					
					}
				}
			}
		}
	}


	@include breakpoint(medium down){ 

		#top{
			position: fixed;
			z-index: 9999;
			width: 100%; 
			-webkit-box-shadow: 0px 4px 75px -17px rgba(242,242,242,1);
			-moz-box-shadow: 0px 4px 75px -17px rgba(242,242,242,1);
			box-shadow: 0px 4px 75px -17px rgba(242,242,242,1);
		}

	}

	
	@include breakpoint(medium up){ 


	}


	@include breakpoint(large up){ 
		
		#top{

		}


	}

	@include breakpoint(xlarge up){ 


	}


}