@mixin project-layout{

	body{
		color: $black;  /* $primary-color; */	
		font-weight: 300;
	}

	ul{
		list-style-type: none;
	}

	input:focus,
	select:focus,
	textarea:focus,
	button:focus,
	img,
	span {
	    outline: none;
	}

	input.left{float:left;}
	input.right{float: right;}
	.bold{font-weight: 600;}

	.spacer{  
		div{ height:1px; border-top:1px solid #ececec;} 
	}

	.off-canvas.is-transition-overlap{
		z-index: 99999;
	}

	#offCanvasCart{
		z-index: 100000;
	}
	.off-canvas{
		background-color: $white;

		.off-canvas-header{
			border-bottom: 1px solid $light-gray;
			padding: 1rem 0;
			color: $black;
			margin-bottom: 0rem; 
		    position: sticky;
		    top: 0;
		    background-color: $white;
		    width: 100%;
		    z-index: 100;			

			span.title{
				text-transform: uppercase;
				font-size: 0.875rem;
				font-weight: 600;
			}

			button{
				span{ color: $black; }
			}
		}

		#off-canvas-cart-content{
			width: 100%;
			
			.cart-list{
				.cart-item{
					width: 100%;
					min-height: 120px;
					/* background: beige; */
					padding: 2rem 0;
					border-bottom: 1px solid $light-gray;		
					
					.image{
						width: 25%;
						/* background: red; */
						padding-right: 16px;
						position: relative;
						float: left;							
								
						img{
						}

						span{
						    position: absolute;
						    top: -1rem;
						    right: 9px;
						    display: block;
						    background: $blue;
						    width: 20px;
						    height: 20px;
						    border-radius: 10px;
						    font-size: .875rem;
						    font-weight: 600;
						    color: white;
						    text-align: center;
						}
					}

					.name{
					    width: 53%;
					    position: relative;
					    /* background: green; */
					    float: left;
					    padding-left: 5px;
					    a{
						    font-size: 0.875rem;
						    color: black;
						    font-weight: 600;
					    }
					}

					.price{
					    position: relative;
					    /* background: pink; */
					    width: 22%;
					    float: left;
					    text-align: right;
					    font-size: 0.875rem;
					    font-weight: 600;
					    padding-left: 1.5rem;
					    color: black;

					    span{
					    	display: block;
					    	cursor: pointer;
					    	color: $blue;
					    }
					}
				}
			}

			.cart-footer{
				width: 100%;
				position: sticky;
				bottom: 0;
				background-color: $white;
				z-index: 100;

				div:nth-child(1){
					color: $black;
					border-bottom: 1px solid $light-gray;
					font-weight: 600;
					font-size: 1rem;		
					padding: 1rem 0;			

					span{ display:block; float:right; }
				}
				div.continue{
					a{
						color: $black;
						background: $white;
						margin-bottom: 3px;
						font-size: 1rem;						
					}
				}
			}
			/*
			table{
				width: 100%;

				tbody{
					height: 100%;
					tr{
						td{
							border-bottom: 1px solid $light-gray;
						}
					}

				}
				tfoot{

				}

			}
			*/
		}
	}

	#offCanvasLogin{
		.first-row{margin-top: 4rem;}
	}

	#offCanvasMenu{
		margin-top: 4.75rem;
		box-shadow: none; 
	}
	/* -------old & unused \/ -----------  */

	.row.normal{margin:0 auto;}
	.nopadding{padding:0!important;}

	.center{text-align: center;}  
	.moz-center{ text-align: -moz-center; }
	.webkit-center{text-align: -webkit-center; }

	.c-white{color:$white;}
	.c-black{color:$black;}
	.c-blue{color:$blue;}

	@mixin box-shadow{ box-shadow: 0px 18px 19.53px 1.47px rgba(58, 82, 115, 0.1); }
	@mixin box-shadow-2{ box-shadow: 0px 10px 10px 0px rgba(117, 116, 116, 0.3); }

	.box-shadow{ @include box-shadow; }
	.box-shadow-2{ @include box-shadow-2; }

	.hero{
		position: relative;
		
		.hero-container{
			position:absolute;
			top:50%;
			left:0;
			transform:translateY(-50%);
		}

		.hero-container.full{
			width:100%;
		}
		.hero-container.center{
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
		.hero-container.left{
			left: 0;
		}		
		.hero-container.right{
			left: initial;
			right: 0;
		}		

		.hero-overlay{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background-color: rgba(0,0,0,0.1);
			&.light{
				background-color: rgba(0,0,0,0.2);
			}
			&.medium{
				background-color: rgba(0,0,0,0.3);
			}
			&.strong{
				background-color: rgba(0,0,0,0.4);
			}
			&.extra-strong{
				background-color: rgba(0,0,0,0.5);
			}

		}	


		h1, h2, h3, h4, h5, h6{
			text-shadow: 0px 4px 13.26px rgba(0, 0, 0, 0.17);
		}	

		h1{
			font-size: 2.5rem;
		}

	}	


	.top-spacer{
	    border-bottom: 2px solid $black;
	    margin: 0.5rem 0;			
	}

	.breadcrumbs li:not(:last-child)::after { top: -1px; color: $blue; }

	.reveal-header{
		position: absolute;
		height: 8%;
		z-index: 9910;
		width: 100%;
		.close-button{
			font-size: 3.5rem;
		}
	}

	.slick-slider{

		.slick-arrow{
		    position: absolute;
		    height: 4rem;
		    width: 4rem;
		    z-index: 1;
		    top: 50%;		
		    transform: translate3d(0, -50%, 0);
		    
		    i{
				border: solid $navy;
				display: inline-block;
				cursor: pointer;    
				padding: 12px;		
				border-width: 0 12px 12px 0;
		    }

			&.small{ 
				i{ padding: 8px;  border-width: 0 8px 8px 0;	}		
			}
			&.tiny{ 
				i{ padding: 4px;  border-width: 0 4px 4px 0;	}	
			}
		}
		.slick-prev{
			left: 3%;
			i{ transform: rotate(135deg); -webkit-transform: rotate(135deg); }
			&.tiny, &.small{ left: -6%; }
		}
		.slick-next{
			right: 3%;
			i{ transform: rotate(-45deg); -webkit-transform: rotate(-45deg); }
			&.tiny, &.small{ right: -6%; }
		}

		.slick-slide{
			text-align: center;
			width: 100%;
			border: 0;
			img{
				display: inline-block;
			}
		}
		.slick-slide:focus{ outline: none; }
	}

	.slick-preview{
		width: 100%;
		button{ display: none!important; }
		&:hover button{ display: inline-block!important; }
		
		.slick-slide{ img{ cursor: -webkit-zoom-in;  } 	}
		.slick-slide:focus{ outline: none; }
	}

	.slick-thumbs{
		width: 100%; 
		height: 100px;
		margin-top: 1px;

		button{ display: none!important; }
		&:hover button{ display: inline-block!important; }


		.slick-list{
			width: 100%;
			height: 100%;

			.slick-track{
				height: 100%;

				.slick-slide{
					width: 133px;
					height: 100px;

					img{ height: 100%;	}
				}
				.slick-slide:focus{ outline: none; }
			}
		}
		div{ float: left; }
	}


	#posts-wrapper, #post-wrapper{
		margin-bottom: 3rem;
	}

	#post-wrapper{
		.hero{
			max-height: 300px;
			overflow: hidden;

			img{
				width: 100%;
			}
		}

		.post-content{
			color: $black;
			padding: 1.5rem;  

			.excerpt{
				font-weight: 600;
				font-size: 1.2rem;
				padding-bottom: 2rem;
			}

			.post-body{
				font-size: 1.1rem;

				h3{
					font-size: 1.4em;
					padding: 1rem 0 1.5rem 0;
				}

				ul{
					list-style-type: initial;

					li{
						padding: 0.5rem;
					}
				}
			}

		}
	}

	.advice-item{
		
		padding-bottom: 1.5rem;
		margin-bottom: 2rem;
		text-align: center;

		img{
			margin-bottom: 1.5rem;
		}
		span{
		    display: block;
		    margin-bottom: 0.5rem;
		    padding: 0 1.6rem;

		    a{
			    font-size: 1.4rem;
			    font-weight: 400;
			    color: $black;
		    }
		}
		p{
			color: $black;
			padding: 1rem 1rem 0.5rem 1rem;
		}
		.button{
			padding: 1rem 4rem;
			font-weight: 700;
			background-color: $light-gray;
			font-size: 1rem;
			color: $black;
		}

		&:hover{
			@include box-shadow;

			.button{
				background-color: $navy;
				color: $white;
			}
		}
	}


	h1, h2, h3{
		&.content-header{
			padding: 3rem 0;
			text-align: center;
			font-size: 2.5rem;
			color: $black;
		}
	}

	.sc{
		color: $black;  
		margin-top: 1rem;
		margin-bottom: 3rem;

		p{
			font-size: 1rem;
		}
	}

	.colorbox{
		height: 5rem;
		text-align: center;
		padding: 2rem 0;
		&.light-gray{ background-color: $light-gray; color: $black; }
		&.medium-gray{ background-color: $medium-gray; color: $black; }
		&.dark-gray{ background-color: $dark-gray; color: $white; }
		&.bg-gray{ background-color: $bg-gray;  color: $black;}
		&.black{ background-color: $black; color: $white; }
		&.white{ background-color: $white; color: $black; }
		&.navy{ background-color: $navy;  color: $white; }
		&.blue{ background-color: $blue; color: $white; }
	}

	#login-desktop{ margin-bottom: 3rem; }

	#content{ margin-bottom: 3rem; }


	.error404{
		color: $black;
		padding: 6rem 0;
		text-align:center;
	}


	.accordion-title:hover, .accordion-title:focus{
		color: $black;
	}

	.accordion-menu-container{
		padding: 1rem 0;

		a{
			color: $black;
		}

		.submenu-toggle::after{
			border-color: $black transparent transparent;   
		}

		.custom-pane-content{
			padding: 0.5rem 0.3rem;
		}
	}


	#static-page-wrapper{
		color: $black;
		margin-bottom: 4rem;
	}


	.central-header{
		color: $black;
		text-align: center;
		width: 100%;
		text-transform: none;

		h2{
			margin: 1rem 0 2rem 0;
			font-size: 1.8rem;
		}
	}


	.section-header{
		text-align: center;
		margin-bottom: 1.5rem;

		h3{}
		h4{
			font-size: 1.4rem;
		}
	}

	.gridLink-container{
		margin-bottom: 3rem;
		
		.gridLink{
			padding-left: 1rem;
			margin-bottom: 0.5rem;

			a{
				color: $black;
			}
		}

	}

	@include breakpoint(medium down) { 

		.custom-pane-content{

			button.aligned{
				margin-top: 1.16rem !important;
				padding: 1rem;
			}
		}

		#offCanvasMenu{
			padding-bottom: 7rem;

			.menu.vertical.accordion-menu{
				width: 100%;
				margin-bottom: 2rem;
			}

			.accordion-menu-container{
				h5{
					margin: 0 1rem 1rem 1rem;
				    color: black;
				    font-weight: bold;
				}
			}

		}

		#mobileSearch{
			.input-group-field{
				box-shadow: none;
				border: 1px solid black;
			}
			button{
				width: 64px;
				background: $white;
				border: 1px solid $black;
				border-left: 0;		
			}
		}

	}

	@include breakpoint(medium up) { 


	}

	@include breakpoint(large up) { 

		.off-canvas{
			&.position-right{
				width: 450px;
			}
		}

		.advice-item{
			span{
				min-height: 74px;

				a{
					font-size: 1.5rem;
				}
			}
		}

		.central-header{

			h2{
				font-size: 2.5rem;
			}
		}		

		#post-wrapper{
			.hero{
				max-height: 300px;

				img{
					margin-top: -30%;
				}
			}

			.post-content{
				.excerpt{
					font-size: 1.4rem;
					line-height: 2.1rem;
				}

				.post-body{
					font-size: 1.1rem;
				}
			}
		}



	}

	@include breakpoint(xlarge up) { 


	}

	@include breakpoint(xxlarge up) { 


	}	

}