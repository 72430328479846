@mixin project-browse{

	.category-image{
		.hero-container{
			img{
				max-width: 5.5rem;
				max-height: 5.5rem;
			}
			a{ color: $white; }
			h3, h2{
				font-size: 1.5rem;
				margin-top: 1rem;
			}
		}
	}


	#browse-sidebar{
		h5{
			/*margin-bottom: 0.5em;*/
    		padding: 0.8rem 0; 

			a{
				color: $black;
				text-transform: uppercase;
				font-size: 0.92rem;
				font-weight: 600;
				display: block;
    			padding: 1rem 0;
			}
   
			&::after{
					border-bottom: 2px solid $black;
					width: 12rem;
					content: " ";
					display: block;
			}		
			
		}

		h5:hover{
			a{ color: $navy; }
			&::after{ border-color: $navy; }
		}

		h5.active{
			a{ color: $blue; }
			&::after{ border-color: $blue; }
		}

		.custom-element{
			display:block;
			width:100%;
			color:#000;
			margin:0.4rem 0;			
		}

		.submenu{
			margin: 0;
			
			li{
				a{
					color: $black;
					display: block;
					padding: 0.5rem 0;
				}

				&.active{
					a{ color: $blue; }
				}
			}

			&.level-1{}
			&.level-2{
				margin-left: 2rem;
			}
		}

	}

	.car-maker{
		margin-bottom: 1rem;

		a{
			color: $black;
			display: block;
			padding: 1rem 0;
			text-align: center;
			font-size: 0.875rem;

			div{
				img{
					margin-bottom: 0.5rem;
				}
			}
		}
	}

	@include breakpoint(large up) { 

		.category-image{
			.hero-container{
				img{
					max-width: 6.5rem;
					max-height: 5.5rem;
				}
				h2, h3{
					font-size: 2.2rem;
				}
			}
		}




	}
}