@mixin project-index{

	.main-slide{
		margin-bottom: 2rem;
	}

	.index-cats{
		margin-bottom: 0rem;

		.hero{
			img.ico{
				max-height: 3rem;
			}
			.hero-container{
				min-width: 15rem;
		
				h3{
					margin-bottom: 2.5rem;
					font-size: 2.25rem;   /* 1.5rem; */
					line-height: 3.2rem;
				}
				a{color: $white; }
				a.btn{
				    color: $white;
				    border: 2px solid $white;
				    padding: 0.9rem 2rem;
				    font-weight: 500;
				    font-size: 1.1rem;					
				}
			}

		}
	}

	.index-section{
		margin-top: 1.2rem;

		.index-section-header{
			
			text-align: center;
			margin: 2rem 0;

			h5{
				font-weight: 600;
				font-size: 1.1rem;
				color: $blue;
			}
			span{
				color: $black;
				font-size: 1.5rem;
				line-height: 2.5rem;
			}

		}

	}

	@include breakpoint(medium down) { 
		.index-cats{
			.grid-x{
				.cell:first-child, .cell:nth-child(2){
					margin-bottom: 1rem;
				}
			}
		}
	}

	@include breakpoint(medium up) { 

	}

	@include breakpoint(large up) { 
   
		.main-slide{
			h1{
				font-size: 4rem;
			}
		}

		.index-cats{
			margin-bottom: 1.8rem;
			.columns{
				margin-bottom: 0;
				.hero{
					.hero-container{
						h3{
							font-size: 1.5rem; /* 1.95rem */
						}
					}
			
				}
			}
		}

		.index-section{
			margin-top: 2rem;
			.index-section-header{
				span{
					font-size: 2.5rem;
					line-height: 5rem;
				}

			}

		}

	}

	@include breakpoint(xlarge up) { 

	}

	@include breakpoint(xxlarge up) { 

	}
}