@mixin project-footer{

	.footer{
		background-color: $extra-gray;
		color: $white;
		padding: 3rem 0 2rem 0;

		.normal{
			@include breakpoint(medium down) {
				/* padding: 0 0 0 2rem; */
			}
		}

		h3{
			line-height: 1.73;
			font-size: 1.375rem;
			font-weight: 500;
			text-transform: uppercase;
			@include breakpoint(large) {
				line-height: 2.379;
				font-size: 1rem;
			}

			img{
				height: 1rem;
    			margin-top: 0.6rem;
			}
		}
		.separator{
			width: 3rem;
			height: 2px;
			background-color: $white;
			display: inline-block;
			margin-top: 0.5rem;
		}
		ul{
			padding: 0;
			margin: 0 0 2rem 0;
			li{
				font-size: 1.125rem;
				line-height: 2.333rem;
				font-weight: 400;
				a{
					font-size: 1.125rem;
					line-height: 2.333rem;
					font-weight: 400;
				}
				@include breakpoint(large) {
					font-size: 0.875rem;
					line-height: 2.286rem;	
					a{
						font-size: 0.875rem;
						line-height: 2.286rem;						
					}				
				}
			}
		}
		a{
			color: $white;
		}

		.copyright{
			font-size: 0.75rem;
			margin:0;
		}
	}

}